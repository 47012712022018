import React from 'react'
import {GrFacebook, GrInstagram} from 'react-icons/gr';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container mx-auto h-full">
                <div className="flex h-full flex-wrap">
                    <div className="footer__col">
                        <h5>Visitez notre boutique en ligne:</h5>
                        <a href="https://neonail.fr/" target="_blank" rel="noopener noreferrer">
                            <img className="h-10" src={require("../../images/logo.png")} alt="" />
                        </a>
                    </div>
                    <div className="footer__col">
                        <h5>Suivez-nous sur:</h5>
                        <div className="footer__social">
                            <a href="https://www.facebook.com/Neonail-France-113628303820188" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrFacebook />
                                </figure>
                            </a>
                            <a href="https://www.instagram.com/neonail_france/?hl=pl" target="_blank" rel="noopener noreferrer">
                                <figure className="footer__ico">
                                    <GrInstagram />
                                </figure>
                            </a>
                        </div>
                    </div>
                    <div className="footer__col">
                        <h5>Page:</h5>
                        <ul className="footer__navigation">
                            <li><Link to ="/">Accueil</Link></li>
                            <li><Link to="https://neonail.fr/inspirations/">Laissez-vous inspirer</Link></li>
                            <li><Link to="/ajoutez-une-inspiration">Ajoutez une inspiration</Link></li>
                            <li><Link to="/abc-des-inspirations">ABC des inspirations</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
