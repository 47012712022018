import React from "react"
import Header from "./layout/Header"
import Footer from "./layout/Footer"
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <section className="content">{children}</section>
      <Footer />
      <CookieConsent
          location="bottom"
          buttonText="JE VOIS"
          style={{ background: "rgba(0,0,0, .75)" }}
          >
        Notre site Web utilise des cookies. En utilisant le site, vous consentez à leur enregistrement ou à leur utilisation. Pour plus d'informations, veuillez consulter notre <a style={{textDecoration: "underline"}} href="https://neonail.fr/politique-de-confidentialite.html" target="_blank">Politique de confidentialité</a>.
      </CookieConsent>
    </main>
  )
}

export default Layout
